<template>
	<div
		class="tn-divider-container"
		:class="size"
	>
		<div
			class="tn-divider"
			:class="[width, { dark }]"
		></div>
	</div>
</template>

<script>
import { defineComponent } from "vue";

/**
 * The **TnDivider** is used to separate content into clear groups. This can be used for thematic breaks between elements on a page, group content in lists and layouts, or separate blocks of content. Visually, they look like horizontal rules or lines.
 *
 * **Usage:**
 *   - to separate content within a card, table, or any white content area to separate large blocks of content
 *   - Use **TnDivider** when whitespace is not enough of a separator
 */
export default defineComponent({
	name: "TnDivider",

	props: {
		/**
		 * This determines the top and bottom padding of the divider. default size is s.
		 */
		size: {
			type: String,
			default: "s",
		},
		/**
		 * This determines the width of the divider. Note that the width is relative to its parent container. Default width is wide (80%).
		 */
		width: {
			type: String,
			default: "wide",
		},
		dark: {
			type: Boolean,
			default: false,
		},
	},
});
</script>

<style lang="scss">
@use "@/assets/scss/variables" as variables;

.tn-divider-container {
	width: 100%;

	.tn-divider {
		height: 1px;
		background: variables.$color-neutrals-300-tint;

		&.dark {
			background: variables.$color-neutrals-700-shade;
		}

		&.narrow {
			width: 60%;
			margin: 0 auto;
		}

		&.wide {
			width: 80%;
			margin: 0 auto;
		}

		&.full {
			width: 100%;
			margin: 0 auto;
		}
	}

	&.s {
		padding: variables.$spacing-s 0;
	}

	&.m {
		padding: variables.$spacing-m 0;
	}

	&.l {
		padding: variables.$spacing-l 0;
	}

	&.xl {
		padding: variables.$spacing-xl 0;
	}
}
</style>
